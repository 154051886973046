const PROJECT_LIST = [
    [{

        "name": "Loop Health",
        "description": "The easiest way to book an appointment, talk to a doctor, view prescriptions, and more.",
        downloadLink: "https://www.loophealth.com/download",
        "image": require("../../Assets/Project/loop.png"),
    }],
    [{

        "name": "Kredx",
        "description": `An Application for investment in portfolio diversification or for a
comprehensive investment platform. makes blue-chip investments
hassle-free. investors can invest in bills/invoices raised against
blue-chip companies and earn lucrative returns seamlessly. KredX
ensures that your alternative investment experience is safe, secure
and satisfactory.
This app provides One-click withdrawal to your bank account. Get
real-time notifications on new deals on the platform Manage your
portfolio on the go and much more.`,
        keyFeature: ["Secured end to end encryption",
            "Secured Login",
            "e-kyc",
            "secured local and app management",
            "bank integration",
            "Realtime updates for above investment features",
            "Single Device login",
            "etc"
        ],
        "androidLink": "https://play.google.com/store/apps/details?id=com.kredxinvest&hl=en_IN&gl=US",
        iOSLink: "https://apps.apple.com/in/app/kredx-invest/id1428625124",
        "image": require("../../Assets/Project/kredx.png")

    }],
    [{
        name: "Constructor project management application",
        description: `Just similar to the other(jira, trello) ticket management system with
some advanced features for tracking the project progress. bar, charts,
evaluations etc.`,
        image: require('../../Assets/Project/realization.webp'),
        iOSLink: 'https://apps.apple.com/in/app/streamliner/id1542965161',
        androidLink: 'https://play.google.com/store/apps/details?id=com.realization.cs&hl=en',
    }],
    [{
        name: "Car Selling Application",
        description: `Developed a comprehensive mobile application for buying and selling cars. The app features an intuitive user interface that allows users to easily browse and search for vehicles based on various filters such as make, model, year, price range, and location. Sellers can create detailed listings with photos, descriptions, and pricing information. Integrated features include secure messaging for communication between buyers and sellers, a scheduling tool for test drives, and a secure payment system. The app also offers vehicle history reports and valuation tools to provide users with transparent and reliable information. Focused on delivering a seamless and user-friendly experience for both buyers and sellers in the automotive market.`,
        image: require("../../Assets/Project/spinny.webp"),
        downloadLink: "",
        iOSLink: "",
        androidLink: "",
    }],
    [{
        name: "Urdu learning application",
        description: `Developed an interactive mobile application designed to facilitate Urdu language learning. The app features integrated video lessons for immersive learning experiences, an on-screen writing function for hands-on practice of the Urdu script, and a robust progress tracking system to monitor and assess user advancements. Focused on creating a comprehensive and engaging platform, the app caters to users at various proficiency levels, ensuring a holistic learning journey.`,
        image: require('../../Assets/Project/urdu.png'),
    }],
    [{
        name: 'Fintech Application',
        description: `Next-generation banking-as-a-service platform that offers complete banking features including instantly create accounts, virtual cards, wallets, payments, and compliance. for you and your family`,
        image: require('../../Assets/Project/chainstarter.png'),
    }],
    [{
        name: 'Live Concert',
        description: `This application was built over zoom sdk, customization is you can
donate money on live online concerts`,
        image: require('../../Assets/Project/liveConcert.png')
    }],
    [{
        name: 'Ambulance Tracking application',
        description: (<p>
            Developed a robust mobile application using React Native for real-time tracking and dispatching of ambulances for <strong>Singapore</strong>.
            The application enables emergency dispatchers to efficiently manage ambulance fleets and respond to emergencies promptly. Key features include
            real-time GPS tracking to ensure accurate location monitoring of ambulances on a map interface, an emergency alert system for instant notifications
            and alerts for new emergency requests, and route optimization to find the fastest and most efficient routes for ambulances to reach destinations.
            The app also incorporates secure communication channels between dispatchers, ambulance drivers, and medical staff, and provides reporting and
            analytics tools to track response times, resource utilization, and performance metrics. Focused on enhancing emergency medical services by leveraging
            modern technology to improve response efficiency and patient outcomes.
        </p>),
        downloadLink: 'https://royalambulance.com.sg/',
        image: require("../../Assets/Project/royal.png")
    }],
    [{
        name: 'BLE Connection',
        description: `In Lockdown, worked on a Singapore based application for capturing
a distance between two devices by using BLE.`,
        downloadLink: '',
        image: require('../../Assets/Project/ble.png')
    }],
    [{
        name: 'Product Service Consumer App',
        description: `A React-Native based application to solve post-sales service management Issues for mutliple brands into, as it
can be showcased to the organisation who are interested to provide services for their product
using a single application.`,
        downloadLink: '',
        image: require('../../Assets/Project/avanta.png'),
    }],
    [{
        name: 'Product Service Contractor/ Service Engineer App',
        description: `The application is intended to provide solution support for the consumer via Service Engineer and Service Contractor for resolving the product complaint raised by the consumer. This application manages all track records of the raised complaint and helps service engineers to manage technical solutions. Using this application Service Contractor can track the location of Service Engineer.`,
        downloadLink: '',
        image: require('../../Assets/Project/avanta.png'),
    }],
    [{
        name: 'Tele Consultation',
        description: `Similar like other doctor patient applications. with video consultation. a major part of this application, video calling feature supports the dubai region where other video calling platforms do not wor`,
        downloadLink: '',
        image: require('../../Assets/Project/Tele Consultation.png'),
    }],
    [{
        name: ' Tele Medicine',
        description: ` This application is for doctors. By using this application doctors can
prepare their prescription by using speech to text. This application has a large medicine dictionary.`,
        downloadLink: '',
        image: '',
    }],
    [{
        name: 'Application for Gym',
        description: `This application is for marking your attendance in gym. and your
daily workout routine.`,
        downloadLink: '',
        image: require('../../Assets/Project/gymapp.png'),
    }],
    [{
        name: 'Aimbetter',
        description: `This App is based on React-native which will help you to maintain
your server and report your server issues and bugs.
this will help to import the server performance`,
        downloadLink: '',
        image: require("../../Assets/Project/aimbetter1.webp"),
    }],
    [{
        name: 'Speech To Text Live Meeting App',
        description: `This App is based on React-native where you can host a live
meeting.
with speaker and listener.
This live meeting creates a speech to text of speaker speech.as well
as stores the audio transcript.`,
        downloadLink: '',
        image: require('../../Assets/Project/ibn.png'),
    }],
    [{
        name: 'Sneeze-o-rama',
        description: `
        Sneeze-O-Rama is a fun, easy game that lets you try and beat modern AI technology by tricking the app with your sneeze!

        Choose a nickname so you can score points, look through some tips on how to make yourself sneeze, and then start recording!

        You'll have 10 seconds to record a real or fake sneeze.
        Our AI will try to recognize if your sneeze was real or fake. If it thinks it was real - you score a point!
        Super-dramatic sneezes score double points!!
        As a bonus, if we decide you weren't faking, find out what your sneeze type says about your personality!
        Keep playing to score as many points as possible and win a place in our Hall of Fame!

        We also invite you to spin the wheel of chance and listen to other people sneezing. (Or trying to!) And if you feel like it, start up a conversation with them. (Anonymously, of course.)`,
        downloadLink: "https://sneeze-o-rama.en.aptoide.com/app",
        //'https://play.google.com/store/apps/details?id=com.sneezorama&hl=en_IN&gl=US&pli=1',
        image: require("../../Assets/Project/sneeze.webp"),
    }],
    [{
        name: 'PlaySive',
        description:
            //          `This App is based on React-native where you can play multiple
            // games and you can play multiplayer games with your friends as
            // well.`,
            `Playsive is a social gaming platform where you can invite your friends to compete in thrilling games. Stay connected by monitoring your friends' top scores and wins, striving to ascend the leaderboards, and discovering new games as you reach new achievements and milestones.`,
        downloadLink: '',
        image: require("../../Assets/Project/playsive.jpg"),
    }],
    [{
        name: 'Car Comparison App',
        description: `This App is based on React-native which helps to purchase a car or
lps to purchase a car or
to get details or comparing with other cars.
here user can get aware with the specification of the car and add
accessories while purchasing the car from this app either user will
get a price estimation`,
        downloadLink: '',
        image: '',
    }],
    [{
        name: 'E-Auction App (AgroBatida)',
        description: `This is based on React-native which helps to create an auction and
purchases a product from auction and other that helps to create
whole details.`,
        androidLink: "https://play.google.com/store/apps/details?id=com.agrobatida&hl=en",
        iOSLink: "https://apps.apple.com/br/app/agrobatida/id14563",
        image: require("../../Assets/Project/agrobatida.webp"),
    }],
    [{
        name: 'ALavadeira',
        description: `A React-Native based automated Laundry and dry-cleaning services on a subscription basis,  It provides services including online tracking, status checking, accessing information about deliveries, and invoicesfor customers and clients. The app handles daily and special garment care, with options for bag and hanger packing. It supports multi-level user roles to streamline tasks.`,
        iOSLink: 'https://itunes.apple.com/in/app/alavadeira-com/id1373846061?mt=8',
        androidLink: "https://apkpure.com/alavadeira-com/com.alavadeira",
        downloadLink: '',
        image: require('../../Assets/Project/alavadeira.png'),
    }],
    [{
        name: 'ALavadeira Admin App',
        description: `A React-Native App Having a bar-code Reader And Driver Tacking
Monitor and to fetch details of the client location and cloths details`,
        downloadLink: 'https://tracxn.com/d/companies/alavadeira/__hP0UiuFnPaKqmw8iCW4Rj7Kx8LUiBPoIh6ENTG6sbcg#about-the-company',
        image: require('../../Assets/Project/alavadeira.png'),
    }],
    [{
        name: 'ItsMee.ch',
        description: `This is the Image and Blog Sharing App which reflect only for 24
hours. If somebody like that post it increase the post time
Duration
If somebody will dislike that post it will decrease time Duration.`,
        downloadLink: '',
        image: '',
    }],
]


export default PROJECT_LIST;